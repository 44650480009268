/** @jsx jsx */


import OrtYaml from '../data/orte.yaml'
import ProjekteYaml from '../data/projekte.yaml'
import KategorienYaml from '../data/veranstaltungkat.yaml'
import Layout from '../components/layout'
import MySEO from '../components/seo'
import {jsx} from 'theme-ui'
import SelectBox from '../components/SelectBox'



const Projekte = () => {
    
    const orte = OrtYaml.content
    const kategorien = KategorienYaml.content
    const thema= ProjekteYaml.content
  
   
    if(orte&&kategorien&&thema)
    return(
        <Layout>
             <MySEO title="Projekte im Oberen Mittelrheintal" description="Liste von Projekten des K.O.M im Oberen Mittelrheintal" />
          {/* <Flex sx={{width:"100%",justifyContent:"flex-end",alignItems:'flex-end'}}>
            <Box sx={{p:2,textAlign:'right'}}>
              <Text sx={{color:'red !important',fontSize:[4,5]}}>&rarr; Mitmachen?</Text>
              <Link sx={{pr:1,color:'text'}}to="/artists/sogehts">So geht´s</Link>
            </Box>
          </Flex> */}
           <div>
 
           <SelectBox kategorien={kategorien} orte={orte} thema={thema} titel="Projekte" />
         </div> 
        
      
        </Layout>
    )
    return null
}
        
export default Projekte